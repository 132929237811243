
.DescontosLabel{
    font-size: 18px;
}
.DescontosAlerta{
    font-size: 16px;

    
}
.DescontosAvisoLimite{
    font-size: 14px;

}
.Texto__Btn{
    font-size: 11px;
}
@media (max-width: 320px) {
    .Container_Label{
     
    } 
    .conteiner_alerta{
        height: "3rem";
    }
    .DescontosLabel{
        font-size: 14px;
    }
    .DescontosAlerta{
        font-size: 12px;
       
     
    }
    .DescontosAvisoLimite{
        font-size: 12px;
    }
  }